import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    refreshUrl: String,
  };

  #isFocusing;

  connect() {
    this.#isFocusing = false;
  }

  disconnect() {
    this.#isFocusing = false;
  }

  #onFocus() {
    if (this.#isFocusing) {
      return;
    }

    this.#isFocusing = true;
    console.log(`[NewDayController]`, "Owl is visible...");
    const todayEl = document.querySelector("[data-today]");

    if (!todayEl) {
      return;
    }

    const todayElDate = todayEl.dataset.date;

    const now = new Date();
    const currentDate = new Date(
      now.getTime() - now.getTimezoneOffset() * 60000,
    )
      .toISOString()
      .split("T")[0];

    if (currentDate !== todayElDate) {
      console.log(
        `[NewDayController]`,
        "Refreshing page since today has become tomorrow...",
      );

      window.Turbo.visit(this.refreshUrlValue, { action: "replace" });
    }

    this.#isFocusing = false;
  }

  onVisibilityChange() {
    if (document.visibilityState === "visible") {
      this.#onFocus();
      console.log(`[NewDayController] Dispatching owl-visible event...`);
      window.dispatchEvent(new CustomEvent("owl-visible"));
    } else {
      window.dispatchEvent(new CustomEvent("owl-hidden"));
    }
  }
}
