import { Controller } from "@hotwired/stimulus";
import { getWeek } from "date-fns";

export default class extends Controller {
  static targets = ["progressBar", "tickContainer"];

  connect() {
    this.updateProgressBar();
    this.addChartTicks();
  }

  updateProgressBar() {
    const currentWeek = this.getCurrentWeek();
    const totalWeeks = 52; // Assuming 52 weeks in a year
    const percentageComplete = (currentWeek / totalWeeks) * 100;

    this.progressBarTarget.style.width = `${percentageComplete}%`;
  }

  addChartTicks() {
    const totalWeeks = 52;
    const tickPositions = [0, 13, 26, 39, 52]; // Start, every quarter, and end
    const currentWeek = this.getCurrentWeek();

    tickPositions.forEach((week, index) => {
      const tick = document.createElement("div");
      tick.className = "chart-tick";
      tick.style.position = "absolute";

      const leftPosition = (week / totalWeeks) * 100;
      tick.style.left =
        index === tickPositions.length - 1
          ? "calc(100% - 2px)"
          : `${leftPosition}%`;

      tick.style.top = "-3px";
      tick.style.height = "3px";
      tick.style.width = "2px";
      tick.style.backgroundColor =
        week <= currentWeek ? "hsl(var(--accent))" : "currentColor";
      this.tickContainerTarget.appendChild(tick);
    });
  }

  getCurrentWeek() {
    return getWeek(new Date());
  }
}
