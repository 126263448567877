import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"];

  connect() {
    this.inputTarget.addEventListener("input", this.validate.bind(this));
  }

  validate() {
    const datePattern = /^\d{4}-\d{2}-\d{2}$/;
    const value = this.inputTarget.value;
    if (!datePattern.test(value) || !this.isValidDate(value)) {
      this.inputTarget.setCustomValidity(
        "Please enter a valid date in the format YYYY-MM-DD.",
      );
    } else {
      this.inputTarget.setCustomValidity("");
    }

    this.inputTarget.reportValidity();
  }

  isValidDate(dateString) {
    const [year, month, day] = dateString.split("-").map(Number);
    const date = new Date(year, month - 1, day);
    return (
      date.getFullYear() === year &&
      date.getMonth() === month - 1 &&
      date.getDate() === day
    );
  }
}
