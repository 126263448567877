import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";

import { hideAll } from "tippy.js";

export default class extends Controller {
  static targets = [
    "list",
    "newTaskForm",
    "newTaskInput",
    "addMoreTasksCheckbox",
  ];

  static values = {
    url: String,
    date: String,
    newTaskUrl: String,
    mode: String,
  };

  newTaskInputTargetConnected() {
    this.newTaskInputTarget.focus();
  }

  connect() {
    if (this.hasListTarget) {
      this.sortable = new Sortable(this.listTarget, {
        animation: 150,
        delay: 250,
        delayOnTouchOnly: true,
        touchStartThreshold: 10,
        easing: "cubic-bezier(1, 0, 0, 1)",
        group: "shared",
        filter:
          ".new-task-input, .editing, .non-sortable, [data-sortable-handle-off]",
        preventOnFilter: false,
        fallbackOnBody: true,
        onMove: (evt) => {
          return (
            !evt.related.classList.contains("editing") &&
            !evt.related.classList.contains("new-task-input") &&
            !evt.related.classList.contains("non-sortable")
          );
        },
        onAdd: this.handleAdd.bind(this),
        onEnd: this.updateOrder.bind(this),
        ghostClass: "sortable-ghost",
        chosenClass: "sortable-chosen",
        dragClass: "sortable-drag",
      });

      this.disableHandle();

      this.mutationObserver = new MutationObserver(
        this.disableHandle.bind(this),
      );
      this.mutationObserver.observe(this.element, { childList: true });
    }
  }

  disconnect() {
    if (this.sortable) {
      this.sortable.destroy();
    }
  }

  disableHandle() {
    let listToDisable = this.element.querySelectorAll("li"); //needed for IOS devices on Safari browser (FIX)
    if (listToDisable) {
      listToDisable.forEach((item) => {
        if (!item.querySelector("input")) {
          item.addEventListener("touchstart", (event) => {
            event.preventDefault();
          });
        }
      });
    }
  }

  handleAdd(event) {
    const newTaskInput = event.to.querySelector(".new-task-input");
    if (newTaskInput && event.to.children.length === 2) {
      event.to.insertBefore(event.item, newTaskInput);
    }
  }

  updateOrder(event) {
    const taskId = event.item.dataset.taskId;
    const newPosition = event.newIndex + 1;

    const destinationTaskSetId = event.to.dataset.taskListDateValue;
    const updateUrl = `/tasks/${taskId}`;
    const headless = this.modeValue === "headless";

    fetch(updateUrl, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
      body: JSON.stringify({
        task: {
          id: taskId,
          position: newPosition,
          date: destinationTaskSetId,
          headless: headless,
        },
      }),
    })
      .then((response) => response.text())
      .then((html) => {
        Turbo.renderStreamMessage(html);
      })
      .catch((error) => console.error("Error updating task:", error));
  }

  focusNewTaskInput(event) {
    // Prevent focusing when clicking on existing tasks or the input itself
    if (
      !event.target.closest("[data-task-id]") &&
      event.target !== this.newTaskInputTarget
    ) {
      this.newTaskInputTarget.focus();
    }
  }
  addTask(event) {
    event.preventDefault();
    const description = this.newTaskInputTarget.value.trim();
    const addMoreTasks =
      this.hasAddMoreTasksCheckboxTarget &&
      this.addMoreTasksCheckboxTarget.checked;

    const headless = this.modeValue === "headless";

    if (description) {
      fetch(this.newTaskUrlValue, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "text/vnd.turbo-stream.html",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
            .content,
        },
        body: JSON.stringify({
          task: { description, date: this.dateValue, headless: headless },
        }),
      })
        .then((response) => response.text())
        .then((html) => {
          Turbo.renderStreamMessage(html);

          if (this.hasAddMoreTasksCheckboxTarget) {
            if (!addMoreTasks) {
              hideAll();
            }
          }

          this.newTaskInputTarget.value = "";
        })
        .catch((error) => console.error("Error updating task:", error));
    }
  }
}
