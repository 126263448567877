import "~/controllers";
import "@hotwired/turbo-rails";
import "trix";
import "@rails/actiontext";
import ahoy from "ahoy.js";
import TextareaAutogrow from "stimulus-textarea-autogrow";
import jstz from "jstz";
import LocalTime from "local-time";

import "./production_svgs";

// import Flatpickr
import Flatpickr from "stimulus-flatpickr";

// Import style for flatpickr
import("flatpickr/dist/flatpickr.css");

import("intro.js/introjs.css");

// Manually register Flatpickr as a stimulus controller
window.Stimulus.register("flatpickr", Flatpickr);

ahoy.configure({
  visitsUrl: "/api/internal_metrics/visits",
  eventsUrl: "/api/internal_metrics/events",
  useBeacon: true,
  startOnReady: true,
  trackVisits: true,
  cookies: true,
  visitDuration: 4 * 60, // 4 hours
  visitorDuration: 2 * 365 * 24 * 60, // 2 years
});

window.Stimulus.register("textarea-autogrow", TextareaAutogrow);
LocalTime.start();

function setCookie(name, value) {
  const expires = new Date();
  expires.setTime(expires.getTime() + 24 * 60 * 60 * 1000);
  document.cookie = name + "=" + value + ";expires=" + expires.toUTCString();
}

// Rails doesn't support every timezone that Intl supports
function findTimeZone() {
  const oldIntl = window.Intl;
  try {
    window.Intl = undefined;
    const tz = jstz.determine().name();
    window.Intl = oldIntl;
    return tz;
  } catch (e) {
    // sometimes (on android) you can't override intl
    return jstz.determine().name();
  }
}

const timeZone = findTimeZone();
setCookie("timezone", timeZone);

document.addEventListener("turbo:render", function (event) {
  //f (!event.detail.isPreview) return;

  console.log("Preparing cache");
  const journallingEl = document.querySelector(".entries-view ul");

  if (!journallingEl) return;

  const targetElement = journallingEl.lastElementChild;
  if (targetElement) {
    targetElement.scrollIntoView({ behavior: "instant", block: "center" });
  }
});

// document.addEventListener("turbo:frame-missing", async function (event) {
//   event.preventDefault();

//   try {
//     const registrations = await navigator.serviceWorker.getRegistrations();
//     await Promise.all(
//       registrations.map((registration) => registration.unregister()),
//     );
//     location.reload();
//   } catch (error) {
//     console.error("Error updating app:", error);
//   }
// });
