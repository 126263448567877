import { Controller } from "stimulus";
import introjs from "intro.js";

export default class extends Controller {
  connect() {
    setTimeout(() => {
      this.showTour();
    }, 500);
  }

  #isMobile() {
    return window.innerWidth < 768;
  }

  #desktopElement(el) {
    if (this.#isMobile()) {
      return null;
    }

    return el;
  }

  showTour() {
    introjs()
      .setOptions({
        positionPrecedence: ["bottom", "top", "right", "left"],
        exitOnOverlayClick: false,
        steps: [
          {
            element: this.#desktopElement(
              document.querySelector("#log-entries"),
            ),
            title: "Check in with yourself",
            intro:
              "Use the journal to reflect on the moment whenever you need to focus and ground yourself. Set intention by noting how you feel, what you just did, and what you need to do next.",
            position: "right",
          },
          {
            element: this.#desktopElement(
              document.querySelector("#tasks-view"),
            ),
            title: "Plan your day in the task list",
            intro:
              "Quickly jot down things you need and want to do today. Hover a task and hit the 3-dot icon to change task status to ’in progress’ and ‘completed’ as you get things done. ",
            position: "right",
          },
          {
            element: this.#desktopElement(
              document.querySelector("#icebox-tab"),
            ),
            title: "Icebox",
            intro:
              "Jot down new ideas, goals &amp; obligations in the icebox so you don’t forget them. Break them into scheduled tasks when you have time to tackle them.",
            position: "right",
          },
          {
            element: this.#desktopElement(
              document.querySelector("#weekly-planner-button"),
            ),
            title: "Weekly Planner",
            intro:
              "Switch to the weekly planner to create task lists for upcoming days. Start each day with a clear plan of action!",
            position: "right",
          },
          {
            element: this.#desktopElement(
              document.querySelector("#journal-view #journal-day"),
            ),
            title: "Use the calendar to review past entries",
            intro:
              "Hit the calendar icon any time you need to remember what happened last week or last month.",
            position: "right",
          },
          {
            element: this.#desktopElement(
              document.querySelector("#feedback-popover"),
            ),
            title: "Tell us your ideas or suggestions",
            intro:
              "You can give any feedback on the app, report bugs, and suggest new features. It goes straight to the developer.",
            position: "bottom",
          },
          {
            title: "Make Owl your default homepage",
            intro:
              "Go ahead and make Owl your default homepage - it's a commitment to yourself to take action, and make time for your own growth and development.",
            position: "bottom",
          },
        ],
      })
      .start();
  }
}
